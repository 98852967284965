<template>
  <v-form
    ref="form"
    autocomplete="off"
    v-model="valid"
    :lazy-validation="lazy"
    onsubmit="return false;"
  >
    <v-container fluid row justify-end>
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-show="x.loading"
      ></v-progress-linear>
      <v-card raised>
        <v-card-title primary-title class="bg-clr">
          <v-btn fab flat icon class="nospace" @click.native="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <span class="headline">&nbsp;New Customer</span>
        </v-card-title>
        <v-card-text class="content-border">
          <div class="heading primary-clr pt-3">Company Details</div>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="muted caption">Name of Company*</div>
              <v-text-field
                label="Name of Company*"
                v-model="companyName"
                required
                class="pt-0 currencyTitle"
                single-line
                :rules="[rules.noWhiteSpace, rules.required, rules.onlyEnglish]"
                maxlength="90"
              />
            </v-flex>
            <v-flex v-if="invoicingCountry == 'KSA'" xs12 md4 class="px-2">
              <div class="muted caption">Name of Company* (in Arabic)</div>
              <v-text-field
                label="Name of Company* (in Arabic)"
                v-model="arabiccompanyName"
                required
                class="pt-0 currencyTitle"
                single-line
                :rules="[
                  rules.noWhiteSpace,
                  rules.required,
                  rules.arabicLanguage,
                ]"
                maxlength="75"
              />
            </v-flex>
            <v-flex xs12 md4 class="px-2">
              <div class="muted caption">Country*</div>
              <v-text-field
                label="Country*"
                v-model="invoicingCountry"
                single-line
                class="pt-0 currencyTitle"
                readonly
                :rules="[rules.noWhiteSpace, rules.required]"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex class="pr-3" xs12 md4>
              <div class="muted caption">Select Invoice Country*</div>
              <v-text-field
                label="Country *"
                class="pt-0 currencyTitle"
                v-model="invoicingCountry"
                single-line
                readonly
                :rules="[rules.noWhiteSpace, rules.required]"
              />
            </v-flex>
            <!-- <v-radio-group
                class="mt-0 pt-0"
                @change="resetcity()"
                v-model="invoicingCountry"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice Country*</div>
                  <div class>
                    <v-layout>
                       
                      <v-radio
                        :label="invoicingCountry"
                        :value="invoicingCountry"
                        color="black"
                        class="col-md-6"
                      ></v-radio> -->
            <!-- <v-text-field
                  readonly
                  label="Select Currency"
                  class="pt-0 currencyTitle"
                  v-model="multipleCountry"
                  :value="multipleCountry"
                  single-line
                      />-->
            <!-- <v-radio
                        v-for="n in multipleCountry"
                        :key="n.value"
                        :label="n.text"
                        :value="n.value"
                        color="black"
                        class="col-md-6"
                      ></v-radio>-->

            <!-- <v-radio label="UAE" value="2" color="black" class="col-md-6"></v-radio>
                      <v-radio label="KSA" value="1" color="black" class="col-md-6"></v-radio>
                      <v-radio label="PAK" value="3" color="black" class="col-md-6"></v-radio>-->
            <!-- </v-layout>
                  </div>
                </div>
              </v-radio-group> -->

            <v-flex class="pr-3" xs12 md4 v-if="invoicingCountry == 'UAE'">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="invoicingCity"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice City*</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="FZ"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="LLC"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="invoicingCountry == 'KSA'">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="invoicingCity"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice City*</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Damman"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Jeddah"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Riyadh"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="invoicingCountry == 'PAK'">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="invoicingCity"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice City*</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Lahore"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Karachi"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Faisalabad"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Multan"
                        value="4"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="invoicingCountry == 'UAE LA'">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="invoicingCity"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice City*</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="DXB"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex class="pr-3" xs12 md4 v-if="invoicingCountry == 'OMAN'">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="invoicingCity"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Select Invoice City*</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Sohar"
                        value="1"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Muscat"
                        value="2"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Salalah"
                        value="3"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-spacer />
          </v-layout>

          <v-layout row>
            <v-flex xs12 sm6 md4>
              <div class="muted caption">Country Code*</div>
              <v-autocomplete
                style="background-color: white !important"
                :rules="[rules.required]"
                :close-on-content-click="true"
                attach
                :items="countryCodes"
                item-text="dialing_code"
                item-value="dialing_code"
                single-line
                class="pt-0 currencyTitle"
                v-model="countryCode"
                label="Country Code*"
              ></v-autocomplete>
            </v-flex>
            <!-- <v-flex xs12 md4>
                        <v-text-field
                                    label="Country Code *"
                                    v-model="countryCode"
                                     single-line
                                    :rules="[rules.countryCode,rules.required]"
                                    required
                                />
            </v-flex>-->
            <v-flex xs12 md4 class="px-2">
              <div class="muted caption">Phone No*</div>
              <v-text-field
                label="Phone No*"
                type="number"
                single-line
                class="pt-0 currencyTitle"
                v-model="phoneNumber"
                :rules="[rules.phoneNumber, rules.required]"
              />
            </v-flex>
            <v-spacer />
          </v-layout>

          <v-layout row>
            <v-flex xs12 md4>
              <div class="muted caption" v-if="invoicingCountry != 'PAK'">
                VAT Number*
              </div>
              <div class="muted caption" v-else>STRN Number</div>
              <v-text-field
                :label="
                  invoicingCountry != 'PAK' ? 'VAT Number*' : 'STRN Number'
                "
                v-model="companyVatNumber"
                single-line
                class="pt-0 currencyTitle"
                autocomplete="off"
                counter
                maxlength="20"
              />
            </v-flex>

            <v-flex xs12 md4 class="px-2">
              <div class="muted caption" v-if="invoicingCountry != 'PAK'">
                Company License Number*
              </div>
              <div class="muted caption" v-else>NTN Number*</div>
              <v-text-field
                :label="
                  invoicingCountry != 'PAK'
                    ? 'Company License Number*'
                    : 'NTN Number*'
                "
                v-model="licenseNumber"
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.noWhiteSpace, rules.required]"
                counter
                maxlength="20"
              />
            </v-flex>
            <v-spacer />
          </v-layout>

          <v-layout row>
            <!-- <v-flex xs12 md4>
              <div class="muted caption">Customer Address*</div>
              <v-text-field
                label="Customer Address*"
                v-model="address"
                single-line
                class="pt-0 currencyTitle"
                required
                :rules="[rules.noWhiteSpace, rules.required]"
              />
            </v-flex> -->
            <v-flex xs12 md4>
              <div class="muted caption">Email Id*</div>
              <v-text-field
                label="Email Id*"
                :rules="[rules.emailRules, rules.required]"
                v-model="emailId"
                single-line
                class="pt-0 currencyTitle"
                required
              />
            </v-flex>
            <v-flex xs12 md4 class="px-2">
              <div class="muted caption">Passsword*</div>
              <v-text-field
                label="Password*"
                v-model="password"
                type="password"
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.required, rules.password]"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs12 md4>
              <div class="muted caption">Repeat Password*</div>
              <v-text-field
                label="Repeat Password*"
                v-model="repeatPassword"
                type="password"
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.required, rules.password]"
              />
            </v-flex>
            <v-flex xs12 md4 class="px-2" v-if="invoicingCountry != 'PAK'">
              <div class="muted caption">License Expiry Date*</div>
              <v-menu
                ref="licenseDateBool"
                lazy
                v-model="licenseDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="licenseDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'License Expiry Date *'"
                  required
                  :rules="[rules.required]"
                  v-model="licenseDate"
                  single-line
                  class="pt-0 currencyTitle"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="licenseDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.licenseDateBool.save(licenseDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-layout row v-if="invoicingCountry != 'PAK'">
            <!-- <v-flex xs12 md4>
              <div class="muted caption">License Expiry Date *</div>
              <v-menu
                ref="licenseDateBool"
                lazy
                v-model="licenseDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="licenseDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'License Expiry Date *'"
                  required
                  :rules="[rules.required]"
                  v-model="licenseDate"
                  single-line
                  class="pt-0 currencyTitle"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="licenseDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.licenseDateBool.save(licenseDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu> -->
            <!-- <v-menu md12 class="expiry">
                                    <v-text-field
                                        :value="licenseDate"
                                        
                                        slot="activator"
                                        label="License Expiry Date *"
                                        prepend-icon="event"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                    <v-date-picker v-model="licenseDate"></v-date-picker>
              </v-menu>-->
            <!-- </v-flex> -->
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="muted caption">Select Industry*</div>
              <v-autocomplete
                label="Select Industry*"
                :items="fetchIndustriesList"
                item-text="name"
                item-value="numericIdentifier"
                v-model="selectedIndustry"
                single-line
                class="pt-0 currencyTitle"
                multiple
                :rules="[rules.requiredLocation]"
              />
            </v-flex>
            <v-flex xs12 md4 pl-1>
              <div class="muted caption">Credit Duration*</div>
              <v-text-field
                label="Credit Duration*"
                v-model="invoiceDay"
                required
                type="number"
                single-line
                suffix="days"
                class="pt-0 currencyTitle"
                :rules="[rules.required, rules.number]"
              />
            </v-flex>
            <!--
            <v-flex xs12 md4 pl-1 v-if="selectedIndustry.includes(67)">
              <div class="muted caption">Enter Industry*</div>
              <v-text-field
                label="Enter Industry*"
                v-model="enterIndustry"
                required
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.required]"
              />
            </v-flex> -->
          </v-layout>
          <!-- <v-layout>
            <v-flex xs12 md4 pl-1>
              <div class="muted caption">Credit Duration*</div>
              <v-text-field
                label="Credit Duration*"
                v-model="invoiceDay"
                required
                type="number"
                single-line
                suffix="days"
                class="pt-0 currencyTitle"
                :rules="[rules.required, rules.number]"
              />
            </v-flex>
          </v-layout> -->
          <v-layout>
            <v-flex xs12 md4>
              <div class="muted caption">Address Line 1*</div>
              <v-text-field
                label="Address Line 1*"
                v-model="address1"
                class="pt-0 currencyTitle"
                single-line
                :rules="[rules.required]"
              />
            </v-flex>
            <v-flex v-if="invoicingCountry == 'KSA'" xs12 md4 pl-1>
              <div class="muted caption">Address Line 1*(in arabic)</div>
              <v-text-field
                label="Address Line 1* (in Arabic)"
                v-model="arabicaddress1"
                class="pt-0 currencyTitle"
                single-line
                :rules="[rules.required, rules.arabicLanguage]"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="muted caption">Address Line 2</div>
              <v-text-field
                label="Address Line 2"
                v-model="address2"
                class="pt-0 currencyTitle"
                single-line
              />
            </v-flex>
            <v-flex v-if="invoicingCountry == 'KSA'" xs12 md4 pl-1>
              <div class="muted caption">Address Line 2 (in Arabic)</div>
              <v-text-field
                label="Address Line 2 (in Arabic)"
                v-model="arabicaddress2"
                class="pt-0 currencyTitle"
                single-line
                :rules="[rules.arabicLanguage]"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="muted caption">City*</div>
              <v-text-field
                label="City*"
                :id="`autocomplete1`"
                v-model="city1"
                class="pt-0 currencyTitle"
                single-line
                placeholder=" Choose City*"
                @input="updateCity()"
                :rules="[rules.required]"
              />
            </v-flex>
            <v-flex xs12 md4 pl-1 v-if="invoicingCountry == 'KSA'">
              <div class="muted caption">City* (in Arabic)</div>
              <v-text-field
                label="City* (in Arabic)"
                v-model="arabiccity1"
                class="pt-0 currencyTitle"
                single-line
                :rules="[rules.required, rules.arabicLanguage]"
              />
            </v-flex>
          </v-layout>

          <div class="heading primary-clr pt-3">Documentation</div>
          <v-layout row>
            <v-flex xs12 md5>
              <div class="upload-btn-wrapper2 pt-2">
                <v-layout row>
                  <v-text-field
                    xs12
                    md4
                    label="Internal Approval"
                    class="currencyTitle width-520"
                    single-line
                  />
                  <button class="btn">Upload</button>
                  <input
                    type="file"
                    name="myfile"
                    @change="internalDoc"
                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                  />
                </v-layout>
              </div>
            </v-flex>
            <div class="upload-btn-wrapper2 pt-2">
              <v-layout row>
                <v-text-field
                  xs12
                  md4
                  label="Miscellaneous Approval"
                  class="currencyTitle width-520"
                  single-line
                />
                <button class="btn">Upload</button>
                <input
                  ref="fileUpload"
                  id="fileUpload"
                  type="file"
                  multiple
                  accept=".pdf, .jpeg, .jpg, .png"
                  @change="uploadDocument"
                />
              </v-layout>
            </div>

            <!-- <div v-if="visa" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="visa">
                  <button type="button" class="m-10">View Visa</button>
                </a>
              </div> -->
          </v-layout>
          <v-layout row>
            <div v-if="internalApproval" class="xs12 md4 pl-1">
              <v-flex xs12 md4>
                <ul style="padding: 0px !important">
                  <li
                    style="position: relative"
                    :key="i"
                    class="list"
                    ref="documentList"
                  >
                    <button
                      type="button"
                      v-show="internalApproval"
                      @click="removeImage(6)"
                    >
                      <span class="close imageClose">&times;</span>
                    </button>
                    <a
                      :href="doc"
                      class="document-link"
                      v-if="
                        internalApproval.substr(
                          internalApproval.lastIndexOf('.') + 1
                        ) === 'pdf'
                      "
                      target="_blank"
                    >
                      <img
                        src="../../assets/pfd.png"
                        class="thumb-img2"
                        height="72"
                        width="75"
                      />
                    </a>

                    <a
                      :href="internalApproval"
                      class="document-link"
                      target="_blank"
                      v-else
                    >
                      <img
                        :src="internalApproval"
                        class="thumb-img2"
                        height="72"
                        width="75"
                      />
                    </a>
                  </li>
                </ul>
              </v-flex>
            </div>

            <div v-if="document.length" class="xs12 md4 pl-10 padding-60">
              <v-flex xs12>
                <ul style="padding: 0px !important">
                  <li
                    style="position: relative"
                    :key="i"
                    v-for="(doc, i) in document"
                    class="list"
                    ref="documentList"
                  >
                    <button
                      type="button"
                      v-show="document"
                      @click="removeImageArray(i)"
                    >
                      <span class="close imageClose">&times;</span>
                    </button>
                    <a
                      :href="doc"
                      class="document-link"
                      v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                      target="_blank"
                    >
                      <img
                        src="../../assets/pfd.png"
                        class="thumb-img2"
                        height="72"
                        width="75"
                      />
                    </a>

                    <a :href="doc" class="document-link" target="_blank" v-else>
                      <img
                        :src="doc"
                        class="thumb-img2"
                        height="72"
                        width="75"
                      />
                    </a>
                  </li>
                </ul>
              </v-flex>
            </div>
          </v-layout>
          <hr class="divider-rule" />

          <div class="heading primary-clr pt-3">Owner Details</div>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="muted caption">Name of Owner/Contact Person*</div>
              <v-text-field
                label="Name of Owner/Contact Person*"
                v-model="ownerName"
                required
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.noWhiteSpace, rules.required]"
              />
            </v-flex>
            <v-flex xs12 pl-4 sm6 md2 v-if="invoicingCountry == 'PAK'">
              <div class="muted caption">Country Code*</div>

              <v-autocomplete
                style="background-color: white !important"
                :rules="[rules.required]"
                :close-on-content-click="true"
                attach
                :items="countryCodes"
                single-line
                class="pt-0 currencyTitle"
                v-model="countryCode2"
                item-text="dialing_code"
                item-value="dialing_code"
                label="Country Code*"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 md4 pl-1 v-if="invoicingCountry == 'PAK'">
              <div class="muted caption">Phone Number*</div>
              <v-text-field
                label="Phone Number*"
                v-model="phoneNumber2"
                required
                type="number"
                single-line
                class="pt-0 currencyTitle"
                :rules="[rules.phoneNumber, rules.required]"
              />
            </v-flex>
            <v-spacer />
          </v-layout>

          <v-layout row v-if="invoicingCountry != 'PAK'">
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      label="Upload Passport*"
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">Upload</button>
                    <input
                      type="file"
                      name="myfile"
                      @change="passportChanged"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="passPort" class="pl-2">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="passPort"
                        @click="removeImage(1)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="doc"
                        class="document-link"
                        v-if="
                          passPort.substr(passPort.lastIndexOf('.') + 1) ===
                          'pdf'
                        "
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="passPort"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="passPort"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="passPort" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="passPort">
                  <button type="button" class="m-10">View Passport</button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption">Passport Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="passportDateBool"
                lazy
                v-model="passportDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="passportExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'Passport Expiry*'"
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  v-model="passportExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="passportExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.passportDateBool.save(passportExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row v-if="invoicingCountry != 'PAK'">
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      label="Upload Visa*"
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">Upload</button>
                    <input
                      type="file"
                      name="myfile"
                      @change="visaChange"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="visa" class="pl-3">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="visa"
                        @click="removeImage(2)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="visa"
                        class="document-link"
                        v-if="visa.substr(visa.lastIndexOf('.') + 1) === 'pdf'"
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="visa"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="visa"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="visa" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="visa">
                  <button type="button" class="m-10">View Visa</button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption">Visa Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="visaDateBool"
                lazy
                v-model="visaDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="visaExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'Visa Expiry*'"
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  v-model="visaExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="visaExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.visaDateBool.save(visaExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout
            row
            v-if="invoicingCountry != 'PAK' && invoicingCountry != 'OMAN'"
          >
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      label="Upload Emirates ID*"
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">Upload</button>
                    <input
                      type="file"
                      name="myfile"
                      @change="eidChange"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="emiratesId" class="pl-2">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="emiratesId"
                        @click="removeImage(3)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="emiratesId"
                        class="document-link"
                        v-if="
                          emiratesId.substr(emiratesId.lastIndexOf('.') + 1) ===
                          'pdf'
                        "
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="emiratesId"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="emiratesId"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="emiratesId" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="emiratesId">
                  <button type="button" class="m-10">View Emirates ID</button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption">Emirates ID Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="emirateDateBool"
                lazy
                v-model="emirateDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="emirateExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'Emirates ID Expiry*'"
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  v-model="emirateExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="emirateExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.emirateDateBool.save(emirateExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row v-if="invoicingCountry == 'OMAN'">
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      label="Upload Omani Id*"
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">Upload</button>
                    <input
                      type="file"
                      name="myfile"
                      @change="eidChange"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="emiratesId" class="pl-2">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="emiratesId"
                        @click="removeImage(3)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="emiratesId"
                        class="document-link"
                        v-if="
                          emiratesId.substr(emiratesId.lastIndexOf('.') + 1) ===
                          'pdf'
                        "
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="emiratesId"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="emiratesId"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="emiratesId" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="emiratesId">
                  <button type="button" class="m-10">View Emirates ID</button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption">Oman ID Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="emirateDateBool"
                lazy
                v-model="emirateDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="emirateExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="x.showDate || 'Oman ID Expiry*'"
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  v-model="emirateExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="emirateExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.emirateDateBool.save(emirateExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      :label="
                        invoicingCountry != 'PAK'
                          ? 'Upload VAT Certificate*'
                          : 'Upload STRN Certificate'
                      "
                      l
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">upload</button>
                    <input
                      type="file"
                      name="myfile"
                      @change="vatChange"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="vatCertificate" class="pl-2">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="vatCertificate"
                        @click="removeImage(4)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="vatCertificate"
                        class="document-link"
                        v-if="
                          vatCertificate.substr(
                            vatCertificate.lastIndexOf('.') + 1
                          ) === 'pdf'
                        "
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="vatCertificate"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="vatCertificate"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="vatCertificate" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vatCertificate">
                  <button type="button" class="m-10">
                    {{
                      invoicingCountry != "PAK"
                        ? "VAT Certificate*"
                        : "STRN Certificate"
                    }}
                  </button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption" v-if="invoicingCountry != 'PAK'">
                VAT Certificate Expiry*
              </div>
              <div class="muted caption" v-else>STRN Certificate Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="vatDateBool"
                lazy
                v-model="vatDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="vatCertificateExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="
                    invoicingCountry != 'PAK'
                      ? 'VAT Certificate Expiry*'
                      : 'STRN Certificate Expiry*'
                  "
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="vatCertificateExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="vatCertificateExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.vatDateBool.save(vatCertificateExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <v-layout row>
                <div class="upload-btn-wrapper2 pt-2">
                  <v-layout row>
                    <v-text-field
                      xs12
                      md4
                      :label="
                        invoicingCountry != 'PAK'
                          ? 'Upload Trade License*'
                          : 'Upload NTN Certificate*'
                      "
                      l
                      class="currencyTitle width-500"
                      single-line
                    />
                    <button class="btn">
                      {{ invoicingCountry != "PAK" ? "Upload " : "Upload " }}
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      @change="tradeLicenseChange"
                      accept=".pdf, .jpeg, .jpg, .gif, .png"
                    />
                  </v-layout>
                </div>
                <div v-if="tradeLicense" class="pl-2">
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="tradeLicense"
                        @click="removeImage(5)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="tradeLicense"
                        class="document-link"
                        v-if="
                          tradeLicense.substr(
                            tradeLicense.lastIndexOf('.') + 1
                          ) === 'pdf'
                        "
                        target="_blank"
                      >
                        <img
                          src="../../assets/pfd.png"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>

                      <a
                        :href="tradeLicense"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          :src="tradeLicense"
                          class="thumb-img2"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </v-layout>
              <!-- <div v-if="tradeLicense" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="tradeLicense">
                  <button type="button" class="m-10">
                    {{
                      invoicingCountry != "PAK"
                        ? "View License"
                        : "NTN Certificate"
                    }}
                  </button>
                </a>
              </div> -->
            </v-flex>
            <v-flex class="pl-3" xs12 md3>
              <div class="muted caption" v-if="invoicingCountry != 'PAK'">
                Trade License Expiry*
              </div>
              <div class="muted caption" v-else>NTN Certificate Expiry*</div>
              <span class="muted"></span>
              <v-menu
                ref="tradeDateBool"
                lazy
                v-model="tradeDateBool"
                :close-on-content-click="false"
                transition="scale-transition"
                full-width
                :nudge-right="40"
                min-width="290px"
                :return-value.sync="tradeLicenseExpiryDate"
              >
                <v-text-field
                  slot="activator"
                  :label="
                    invoicingCountry != 'PAK'
                      ? 'Trade License Expiry*'
                      : 'NTN Certificate Expiry*'
                  "
                  required
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  v-model="tradeLicenseExpiryDate"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="tradeLicenseExpiryDate"
                  :min="dateToday"
                  class="minHeight"
                  @change="$refs.tradeDateBool.save(tradeLicenseExpiryDate)"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-spacer />
          </v-layout>

          <hr class="divider-rule" />
          <div class="heading primary-clr pt-3">
            Other Contacts
            <v-btn
              flat
              v-if="contactArr.length <= 3"
              class="add-btn"
              @click.stop="openContact()"
              >Add New Contact</v-btn
            >
          </div>
          <v-layout row style="padding-top: inherit">
            <v-flex v-for="(item, index) in contactArr" :key="index" xs4 pr-4>
              <div class="heading">
                {{ item.name }}
                <button
                  type="button"
                  @click="editContact(index)"
                  class="track-ass-btn green-button"
                >
                  Edit
                </button>
                <button
                  type="button"
                  @click="deleteContact(index)"
                  class="track-ass-btn m-l-10 yellow-button"
                >
                  Remove
                </button>
              </div>
            </v-flex>
          </v-layout>

          <!-- <v-layout wrap>
                        <v-flex md5>
                            <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                                <span class="font16">Passport &nbsp;&nbsp;</span>
                                <input
                                    type="file"
                                    label="Passport"
                                    @change="passportChanged"
                                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                                />
                            </v-flex>
                            <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                                <span class="font16">Visa &nbsp;&nbsp;</span>
                                <input
                                    type="file"
                                    label="Visa Picture"
                                    @change="visaChange"
                                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                                />
                            </v-flex>
                            <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                                <span class="font16">Emirates Id &nbsp;&nbsp;</span>
                                <input
                                    type="file"
                                    label="Emirates ID Picture"
                                    @change="eidChange"
                                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                                />
                            </v-flex>
                            <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                                <span class="font16">Vat Certificate &nbsp;&nbsp;</span>
                                <input
                                    type="file"
                                    label="Vat Certificate Picture"
                                    @change="vatChange"
                                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                                />
                            </v-flex>
                            <v-flex xs12 class="pr-4 marginTop20" sm6 md21>
                                <span class="font16">Trade License &nbsp;&nbsp;</span>
                                <input
                                    type="file"
                                    label="Trade License Picture"
                                    @change="tradeLicenseChange"
                                    accept=".pdf, .jpeg, .jpg, .gif, .png"
                                />
                            </v-flex>
                        </v-flex>
          </v-layout>-->

          <div
            class="my-3"
            v-if="haserror"
            style="width: 50%; margin: auto; text-align: center"
          >
            <v-alert v-model="haserror" class="py-2" outline color="red">{{
              x.error
            }}</v-alert>
          </div>
          <div
            class="my-3"
            v-if="proofErrors && !haserror"
            style="width: 50%; margin: auto; text-align: center; color: red"
          >
            {{ proofErrors }}
          </div>
          <hr class="divider-rule" />
          <v-layout>
            <v-spacer />
            <v-btn
              size="large"
              color="grey darken-3"
              flat
              style="color: white"
              @click.native="close"
              >Cancel</v-btn
            >
            <v-btn
              size="large"
              color="orange darken-1"
              @click.native="check()"
              style="color: white"
              :loading="x.loading"
              >Register Customer</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
      <AddContact
        :onLoad="contactLoad"
        :toShow="contactPopUp"
        :contacts="contactObj"
        :onclose="onAddContact"
      />
      <success-dialog
        content="Customer registration successful"
        :show="x.registerSuccess"
        :onclose="closeRegistration"
      />
    </v-container>
  </v-form>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import moment from "moment";
import {
  StorageKeys,
  countries,
  NatureOfCargosAdd,
} from "../../constants/constants";
import AddContact from "./AddContact";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.invoicingCountry = key.value;
        }
      });
    }

    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete("1");
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "customer-registration") {
        this.$router.push(`/customers`);
      }
      //this.invoicingCountry = val.value;
    });
    this.countryCodes = countries;
    this.cargos = NatureOfCargosAdd;
    this.$eventBus.$on("add-contact-assign-close", () => {
      this.contactPopUp = false;
    });
    this.fetchIndustries();
    this.$eventBus.$on("add-contact-assign-done", (e) => {
      this.contactPopUp = false;
      if (this.contactObj.index == -1) {
        this.contactArr.push(e);
      } else {
        this.contactArr[this.contactObj.index] = e;
      }
    });
  },
  components: {
    SuccessDialog,
    AddContact,
  },
  props: {
    profile: Object,
  },
  data() {
    return {
      phoneNumber2: "",
      countryCode2: "",
      address1: "",
      arabicaddress1: "",
      invoiceDay: null,
      address2: "",
      arabicaddress2: null,
      city1: "",
      city1Error: null,
      arabiccity1: "",
      multipleCountry: "",
      countryCodes: [],
      document: [],
      enterIndustry: "",
      companyVatNumber: "",
      proofErrors: "",
      fetchIndustriesList: [],
      address: null,
      cargos: [],
      selectedIndustry: [],
      invoicingCountry: "",
      invoicingCity: null,
      valid: true,
      lazy: false,
      model: [],
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      contactArr: [],
      contactObj: {
        name: null,
        email: null,
        mobile: null,
        countryCode: null,
        designation: null,
      },
      contactPopUp: false,
      contactLoad: false,

      // ---------------- EXPIRY DATES -------------
      licenseDateBool: false,
      licenseDate: "",

      passportDateBool: false,
      passportExpiryDate: null,

      visaDateBool: false,
      visaExpiryDate: null,

      emirateDateBool: false,
      emirateExpiryDate: null,

      vatDateBool: false,
      vatCertificateExpiryDate: "",

      tradeDateBool: false,
      tradeLicenseExpiryDate: null,

      // -------------------------------------------

      companyName: "",
      arabiccompanyName: "",
      countryCode: "",
      country: "",
      emailId: "",
      licenseNumber: "",
      password: "",
      repeatPassword: "",

      phoneNumber: "",
      city: "",
      ownerName: "",
      passPort: "",
      visa: "",
      internalApproval: "",

      emiratesId: "",
      vatCertificate: "",
      tradeLicense: "",
      processing: false,
      x: {
        loading: false,
        registerSuccess: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },

      rules: {
        required: (value) => !!value || "This field is required.",
        onlyEnglish: (v) =>
          /^[a-zA-Z\s]*$/.test(v) || "Please Enter Valid value",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        password: (v) =>
          /^.{6,}$/.test(v) || "Password should be minimum of 6 characters",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Value",
        requiredLocation: (value) =>
          (!!value && !!value.length) || "This field is required.",
        counter: (value) =>
          (value != null && value.trim().length <= 20) ||
          "Number should contain maximum 20 characters.",
        customerAddress: (v) =>
          /^[a-zA-Z0-9-!@()&^/#$%*?]+([._]?[a-zA-Z0-9-!@()&^/#$%*?]+)*$/.test(
            v
          ) || "Customer address must be valid",
        number: (v) =>
          /^([1-9][0-9]{0,3}|10000)$/.test(v) ||
          "Please enter valid duration greater than 0 less than 10000(Decimal values are not allowed)",
        emailRules: (v) =>
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
            v
          ) || "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        // phoneNumber: (v) =>
        //   /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
        //   "Please Enter Valid Phone No.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
        arabicLanguage: (v) => {
          if (v)
            return (
              /^([أ-ي ]| [\u0600-\u06FF])+$/.test(v) ||
              "Please Enter Arabic language"
            );
        },
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  watch: {},
  methods: {
    removeImageArray(key) {
      this.document.splice(key, 1);
    },
    initLoadingAutocomplete(n) {
      // console.log(n);
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1")
      );
      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        console.log(autocomplete);
        // console.log(place);
        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.city1Error = false;
        } else {
          this.city1Error = true;
        }
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }

        if (x.length === 2) {
          this.city1 = x[0];
        } else {
          this.city1 = y[0];
        }

        // if (x.length == 2) {
        //   this.city = x[0];
        // } else {
        //   this.city = x[1];
        // }
        // this.updateInfo.sourceCity = x[0];
      });

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.

      // console.log(this.dropoff);
    },
    updateCity() {
      if (this.city1Error == false) {
        this.city1Error = true;
      }
    },
    removeImage(statusChange) {
      if (statusChange == 1) {
        this.passPort = "";
      } else if (statusChange == 2) {
        this.visa = "";
      } else if (statusChange == 3) {
        this.emiratesId = "";
      } else if (statusChange == 4) {
        this.vatCertificate = "";
      } else if (statusChange == 5) {
        this.tradeLicense = "";
      } else if (statusChange == 6) {
        this.internalApproval = "";
      }
    },
    async check() {
      this.operationName = "add-new-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.register();
      } else {
        return;
      }
    },
    getUserById() {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      let url = "/admin/getUserById";
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          response.data.data.countrySortName.forEach((e) => {
            if (e == "KSA") {
              var index = "1";
            }
            if (e == "UAE") {
              var index = "2";
            }
            if (e == "PAK") {
              var index = "3";
            }
          });
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    resetcity() {
      this.invoicingCity = null;
    },
    editContact(i) {
      this.contactObj.name = this.contactArr[i].name;
      this.contactObj.email = this.contactArr[i].email;
      this.contactObj.mobile = this.contactArr[i].mobile;
      this.contactObj.countryCode = this.contactArr[i].countryCode;
      this.contactObj.designation = this.contactArr[i].designation;
      this.contactObj.index = i;
      this.contactPopUp = true;
    },
    deleteContact(i) {
      this.contactArr.splice(i, 1);
    },
    openContact() {
      this.contactObj.name = null;
      this.contactObj.email = null;
      this.contactObj.mobile = null;
      this.contactObj.designation = null;
      this.contactObj.countryCode = null;
      this.contactObj.index = -1;
      this.contactPopUp = true;
      this.contactLoad = true;
    },
    onAddContact() {},
    close() {
      this.$router.go(-1);
    },
    cleanJson(customerProfile) {
      let newCustomerProfile = customerProfile;
      if (this.invoicingCountry == "PAK") {
        delete newCustomerProfile["visa"];
        delete newCustomerProfile["passPort"];
        delete newCustomerProfile["emiratesId"];
        delete newCustomerProfile["visaExpiryDate"];
        delete newCustomerProfile["passportExpiryDate"];
        delete newCustomerProfile["emirateExpiryDate"];
        delete newCustomerProfile["licenseDate"];
      }

      delete newCustomerProfile["city"];
      delete newCustomerProfile["city1"];
      delete newCustomerProfile["proofErrors"];
      delete newCustomerProfile["address1"];
      delete newCustomerProfile["enterIndustry"];
      delete newCustomerProfile["arabicaddress1"];
      delete newCustomerProfile["arabiccity1"];
      delete newCustomerProfile["city1Error"];
      delete newCustomerProfile["address"];
      delete newCustomerProfile["arabicaddress2"];
      delete newCustomerProfile["address2"];
      delete newCustomerProfile["vatCertificate"];
      delete newCustomerProfile["internalApproval"];
      delete newCustomerProfile["document"];
      delete newCustomerProfile["phoneNumber2"];
      delete newCustomerProfile["countryCode2"];
      delete newCustomerProfile["multipleCountry"];
      delete newCustomerProfile["fetchIndustriesList"];
      delete newCustomerProfile["countryCodes"];
      delete newCustomerProfile["lazy"];
      delete newCustomerProfile["model"];
      delete newCustomerProfile["valid"];
      delete newCustomerProfile["rules"];
      delete newCustomerProfile["arabiccompanyName"];
      delete newCustomerProfile["repeatPassword"];
      delete newCustomerProfile["processing"];
      delete newCustomerProfile["licenseDateBool"];
      delete newCustomerProfile["passportDateBool"];
      delete newCustomerProfile["dateToday"];
      delete newCustomerProfile["visaDateBool"];
      delete newCustomerProfile["emirateDateBool"];
      delete newCustomerProfile["vatDateBool"];
      delete newCustomerProfile["tradeDateBool"];
      delete newCustomerProfile["contactArr"];
      delete newCustomerProfile["cargos"];
      delete newCustomerProfile["contactObj"];
      delete newCustomerProfile["contactPopUp"];
      delete newCustomerProfile["contactLoad"];
      return newCustomerProfile;
    },

    register() {
      this.x.error = null;
      if (!this.password.match(this.repeatPassword)) {
        this.x.error = "Password and Repeat password don't match";
        return false;
      }
      if (this.$refs.form.validate()) {
        if (this.processing) {
          return;
        } else {
          this.x.error = null;
          const url = `${this.constants.apiUrl}/dmsAdmin/addCustomer`;
          const { x, ...customerProfile } = this._data;

          // const mandatory = [
          // "companyName",
          // "countryCode",
          // "country",
          // "emailId",
          // "licenseNumber",
          // "password",
          // "repeatPassword",
          // "licenseDate",
          // "phoneNumber",
          // "ownerName",
          // "passPort",
          // "visa",
          // "emiratesId",
          // "vatCertificate",
          // "tradeLicense",

          // "passportExpiryDate",
          // "visaExpiryDate",
          // "emirateExpiryDate",
          // "vatCertificateExpiryDate",
          // "tradeLicenseExpiryDate",
          // ];
          // const missingValueForKey = mandatory.find(
          //     key =>
          //         typeof customerProfile[key] !== "boolean" &&
          //         !customerProfile[key]
          // );
          // if (missingValueForKey) {

          //     const field =
          //         missingValueForKey.slice(0, 1).toUpperCase() +
          //         missingValueForKey.slice(1).replace(/([A-Z])/g, " $1");
          //     this.x.error = `Please provide a valid : ${field}`;
          //     return;
          // } else {
          if (this.city1Error == true || this.city1Error == null) {
            this.x.error = "Please enter Valid City";
            return false;
          }
          if (!this.companyVatNumber && this.invoicingCountry != "PAK") {
            this.x.error = "Please enter VAT number";
            return false;
          }
          if (!this.passPort && this.invoicingCountry != "PAK") {
            this.x.error = "Please upload  passport image";
            return false;
          }
          if (!this.vatCertificateExpiryDate) {
            if (this.invoicingCountry != "PAK") {
              this.x.error = "Please select vat certificate expiry date";
            } else {
              this.x.error = "Please select Strn certificate expiry date";
            }

            return false;
          }

          if (!this.visa && this.invoicingCountry != "PAK") {
            this.x.error = "Please upload  visa image";
            return false;
          }
          if (!this.emiratesId && this.invoicingCountry != "PAK") {
            if (this.invoicingCountry == "OMAN") {
              this.x.error = "Please upload  Oman id image";
              return false;
            } else {
              this.x.error = "Please upload emirates id image";
              return false;
            }
          }
          if (!this.vatCertificate && this.invoicingCountry != "PAK") {
            this.x.error = "Please upload  VAT certificate image";
            return false;
          }
          if (!this.internalApproval) {
            this.x.error = "Please upload  Internal Approval image";
            return false;
          }
          if (!this.tradeLicense && this.invoicingCountry != "PAK") {
            this.x.error = "Please upload trade license image";
            return false;
          }
          //  if (!this.vatCertificate && this.invoicingCountry == "PAK") {
          //   this.x.error = "Please upload  STRN certificate image";
          //   return false;
          // }
          if (!this.tradeLicense && this.invoicingCountry == "PAK") {
            this.x.error = "Please upload NTN certificate image";
            return false;
          }

          if (!this.contactArr.length) {
            this.x.error = "Please provide at least one contact";
            return false;
          }
          if (this.contactArr.length > 4) {
            this.x.error = "You cannot add more than 4 contacts";
            return false;
          }

          this.processing = true;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let newCustomerProfile = this.cleanJson(customerProfile);
          newCustomerProfile.country = this.invoicingCountry;

          if (this.address1 && this.address2 && this.city1) {
            newCustomerProfile.address =
              this.address1.toString() +
              "\n" +
              this.address2.toString() +
              "\n" +
              this.city1.toString();
          }

          if (this.address1 && this.city1 && !this.address2) {
            newCustomerProfile.address =
              this.address1.toString() + "\n" + this.city1.toString();
          }
          if (this.vatCertificate) {
            newCustomerProfile.vatCertificate = this.vatCertificate;
          }
          if (this.internalApproval) {
            newCustomerProfile.internalApprovalDoc = this.internalApproval;
          }
          if (this.document.length) {
            newCustomerProfile.miscellaneousDoc = this.document;
          }
          //     if(this.vatCertificateExpiryDate){
          //    newCustomerProfile.vatCertificateExpiryDate=this.vatCertificateExpiryDate;
          //  }
          if (this.invoicingCountry == "PAK") {
            newCustomerProfile.phoneNumber2 = this.phoneNumber2;
            newCustomerProfile.countryCode2 = this.countryCode2;
            newCustomerProfile.invoicingCountry = "3";
          }
          if (this.invoicingCountry == "KSA") {
            newCustomerProfile.invoicingCountry = "1";
            newCustomerProfile.arabicCompanyName = this.arabiccompanyName;
            if (
              this.arabicaddress1 &&
              this.arabicaddress2 &&
              this.arabiccity1
            ) {
              newCustomerProfile.arabicAddress =
                this.arabicaddress1.toString() +
                "\n" +
                this.arabicaddress2.toString() +
                "\n" +
                this.arabiccity1.toString();
            }

            if (
              this.arabicaddress1 &&
              this.arabiccity1 &&
              !this.arabicaddress2
            ) {
              newCustomerProfile.arabicAddress =
                this.arabicaddress1.toString() +
                "\n" +
                this.arabiccity1.toString();
            }
          }
          if (this.invoicingCountry == "UAE") {
            newCustomerProfile.invoicingCountry = "2";
          }
          if (this.invoicingCountry == "UAE LA") {
            newCustomerProfile.invoicingCountry = "4";
          }
          if (this.invoicingCountry == "OMAN") {
            newCustomerProfile.invoicingCountry = "5";
          }
          if (this.invoicingCountry != "PAK") {
            newCustomerProfile.visa = this.visa;
            newCustomerProfile.visaExpiryDate = this.visaExpiryDate;
            newCustomerProfile.passPort = this.passPort;
            newCustomerProfile.passportExpiryDate = this.passportExpiryDate;
            newCustomerProfile.emiratesId = this.emiratesId;
            newCustomerProfile.emirateExpiryDate = this.emirateExpiryDate;
          }
          newCustomerProfile.contacts = [];
          this.contactArr.forEach((e) => {
            newCustomerProfile.contacts.push({
              name: e.name,
              email: e.email,
              designation: e.designation,
              phoneNo: e.mobile,
              countryCode: e.countryCode,
            });
          });
          this.x.loading = true;
          this.axios.post(url, { ...newCustomerProfile }, config).then(
            (response) => {
              if (response.status === 200) {
                this.x.registerSuccess = true;
                this.processing = false;
                //this.$refs.form.resetValidation();
              } else {
                this.processing = false;
                this.x.error = "Failed to Register Customer.";
              }
              this.x.loading = false;
              this.processing = false;
            },
            (error) => {
              this.processing = false;
              this.x.loading = false;
              this.x.error = this.$eventBus.parse(error);
            }
          );
        }
      }
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },
    passportChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 1);
    },
    visaChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 2);
    },
    eidChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 3);
    },
    vatChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 4);
    },
    tradeLicenseChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 5);
    },
    internalDoc(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 6);
    },
    // miscellaneousUpload(e) {
    //   var files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.createImage(files[0], 7);
    // },

    fetchIndustries() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      const url = `${this.constants.apiUrl}/website/industries`;
      this.axios.get(url, config).then((response) => {
        console.log(response.data.data);
        this.fetchIndustriesList = response.data.data;
      });
    },
    createImage(file, status) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
      this.upload(file, status);
    },
    upload(file, statusChange) {
      this.x.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;

          if (statusCode === 200) {
            if (statusChange == 1) {
              this.passPort = response.data.data.original;
            } else if (statusChange == 2) {
              this.visa = response.data.data.original;
            } else if (statusChange == 3) {
              this.emiratesId = response.data.data.original;
            } else if (statusChange == 4) {
              this.vatCertificate = response.data.data.original;
            } else if (statusChange == 5) {
              this.tradeLicense = response.data.data.original;
            } else if (statusChange == 6) {
              this.internalApproval = response.data.data.original;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.x.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.x.loading = false;
        }
      );
    },

    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage2(files[i]);
      });
    },
    createImage2(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload2(file);
      }
    },
    upload2(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 3) {
              this.document.push(response.data.data.original);
            } else {
              this.proofErrors = "Maximum 3 proofs can be uploaded!";
              setTimeout(() => {
                this.proofErrors = "";
              }, 6000);
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.expiry {
  width: 100%;
}
.menu,
.menu__content {
  background: white !important;
}

.jbtn-file {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.thumb-img2 {
  border: 2px solid darkorange;
  border-radius: 8px;
  height: 45px;
  width: 45px;
  margin-left: 5px !important;
}
.width-500 {
  width: 440px;
}

.jbtn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.margin-bottom20 {
  margin-bottom: 20px;
}
.marginTop20 {
  margin-top: 20px;
}
.font16 {
  font-size: 16px;
}
.width-520 {
  width: 480px;
}
.doc-image {
  width: 80px;
  height: 80px;
}

.menuable__content__active {
  min-width: 290px !important;
}
.content-border1 {
  border: 2px solid #ffe0bb;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.padding-60 {
  padding-left: 594px;
}

.upload-btn-wrapper .btn,
.upload-btn-wrapper .view-btn {
  border: 2px solid darkorange;
  color: darkorange;
  background-color: white;
  padding: 8px 20px;
  cursor: pointer !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload-btn-wrapper2 {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper2 .btn,
.upload-btn-wrapper2 .view-btn {
  color: darkorange;
  background-color: white;
  padding: 4px 10px;
  cursor: pointer !important;

  font-size: 14px;
  font-weight: bold;
}

.upload-btn-wrapper2 input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 40px;
}
.add-btn {
  background-color: #ffffff;
  color: darkorange !important;
  border: 2px solid darkorange;
  margin: 0 20px;
  padding: 5px;
}

.red-button {
  border-radius: 5px;
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  border: 2px solid red !important;
}
.yellow-button {
  border-radius: 5px;
  background-color: #ffd65c !important;
  color: #ffffff !important;
  border: 2px solid #ffc107 !important;
}
.list {
  display: inline-block;
  position: relative;
}
.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}
.widthbtn {
  width: 196px;
}
.widthbtn2 {
  width: 166px;
}
.minHeight {
  min-height: 300px;
}
.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
  margin-right: 5px;
}
</style>
